<template>
  <tr>
    <td class="text-center"   v-if="permissions.can_edit_depot"  >
      <VBtn   
      class="btn-edit"
    
      @click="onEdit(depot.uuid)"
      color="primary"
      :class="{sm:!isLarge}"
      small>
      <VIcon
      dense
      >mdi-pencil</VIcon>
      </VBtn>
      <!-- <VIcon
        v-if="permissions.can_delete_depot"
        color="red"
        @click="deleteDialog = true"
      >mdi-delete</VIcon> -->
    </td>
    <td class=" text-center">
      <VIcon :size="isLarge ? '40' : '30' ">mdi-warehouse</VIcon>
    </td>
    <td>{{ depot.name || "--"}}</td>
    <td>{{ depot.description || '--'}}</td>
    <UpdateDepotDialog
      v-if="updateDialog"
      :depot="depot"
      :visible="updateDialog"
      @close="updateDialog = false"
    />
    <DeleteAddressDialog
      :uuid="depot.uuid"
      :visible="deleteDialog"
      @close="deleteDialog = false"
      @delete="onDeleteDepot"
    />
  </tr>
</template>

<script>
import UpdateDepotDialog from './UpdateDepotDialog.vue';
import user from '../../mixins/user';
import DeleteAddressDialog from '../dialog/ConfirmDeleteDialog.vue';
import SizeUi from "../../mixins/SizeUi";

export default {
  name: 'DepotRow',
  mixins: [user,SizeUi],
  components: {
    UpdateDepotDialog,
    DeleteAddressDialog,
  },
  props: {
    depot: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  data: () => ({
    updateDialog: false,
    deleteDialog: false,
  }),
  methods: {
    onEdit(e){
      this.updateDialog = true
      this.$emit('toggleRow',e)
    },
    onDeleteDepot() {
      this.$emit('delete', this.depot.uuid);
      this.deleteDialog = false;
    },
  },
  computed: {
    background() {
      if (this.index % 2 === 0) {
        return '#F8F8F8';
      }
      return 'white';
    },
  },
};
</script>

<style scoped lang="scss">
.td{
  height: 40px;
  padding: 4px 16px!important;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 32px;
  color: #676767;
  text-align: left;
  border-left: 1px solid #F2F2F2;
  &:last-child{
    padding: 4px;
    border-right: 1px solid #F2F2F2;
  }
}
   .ico{
    padding: 0!important;
  }
</style>

<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    @click:outside="showConfirmDialog"
    :persistent="isChanged"
    content-class="cross__dialog"


  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? 'auto' : ''">
          <div class="cross__inner">
      <VBtn fab small  color="white" elevation="0" @click="showConfirmDialog">
        <VIcon>mdi-close</VIcon>
      </VBtn>
      </div>
      <VCardTitle>
        <span class="font-20"  :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{ $t('storages.create_storage') }}</span>

      </VCardTitle>
      <VDivider class="mb-2" />
      <VCardText class="pb-0">

          <div class="text-center" v-if="loading">
            <VProgressCircular
              :size="50"
              color="primary"
              indeterminate
            ></VProgressCircular>
          </div>
          <div class="" v-else>
            <VRow no-gutters>
              <VCol cols="12" class="py-0">
                <VTextField
                  outlined dense
                  :label="$t('form.name')"
                  v-model="depot.name"
                  required
                  @input="checkChanged"
                  :error-messages="titleErrors"
                ></VTextField>
              </VCol>
              <VCol cols="12" class="py-0">
                <v-autocomplete
                 :label="$t('addresses.adressesTitle')"
                  :items="Object.values(addresses)"
                  :item-value="'uuid'"
                  :item-text="'name'"
                  :no-data-text="$t('table.noData')"
                  outlined
                  @change="checkChanged"
                  dense
                  v-model="depot.address_uuid"
                  :error-messages="uuidErrors"
                  small-chips
                  deletable-chips
                />
              </VCol>
              <VCol cols="12" class="py-0">
                <VTextarea
                  outlined dense
                  :label="$t('form.description')"
                  required
                  @input="checkChanged"
                  v-model="depot.description"
                  no-resize
                  counter
                  maxlength="255"
                  rows="2"
                ></VTextarea>
              </VCol>
            </VRow>
          </div>

      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 ">
          <VCol col="6">
            <VBtn
              dark
              block
              class="text-white text-transform-none br-10 w-100"
              @click="onClose"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.cancel') }}
            </VBtn>
          </VCol>
          <VCol col="6" >
            <VBtn

              class="success-bg text-white text-transform-none br-10 w-100"
              block
              :disabled="!isChanged"
              @click="onCreate"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t('btn.create') }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
        <ConfirmLeftDialog
    :visible="visibleConfirm"
    @close="visibleConfirm = false"
    @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ConfirmLeftDialog from '@/components/dialog/ConfirmLeftDialog.vue';
import language from '../../mixins/language';
import depotService from '../../services/request/depot/depotService';
import notifications from '../../mixins/notifications';
import user from '../../mixins/user';
import addressService from '../../services/request/address/addressService';
import EventBus from '../../events/EventBus';

export default {
  name: 'CreateDepotDialog',
  mixins: [language, notifications, validationMixin, user],
  validations: {
    depot: {
      name: { required },
      address_uuid: { required },
    },
  },
  data: () => ({
    loading: false,
    isChanged:false,
    visibleConfirm:false,
    depot: {
      name: '',
      description: '',
      address_uuid: '',
    },
    addresses: [],
  }),
  components:{
ConfirmLeftDialog
  },
  // watch: {
  //   depot: {
  //     deep: true,
  //     handler(e) {
  //       // orderDialog(e);
  //       const values = Object.values(this.depot);
  //         if(values.filter((item) => item).length){
  //         this.isChanged = true
  //         // orderDialog(values.filter((item) => item).length);
  //       }else{
  //         // orderDialog(values.filter((item) => item).length);
  //          this.isChanged = false
  //       }
  //     },
  //   },
  // },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  async mounted() {
    try {
      this.addresses = await addressService.getAddressesList();
    } catch (e) {
      this.setErrorNotification(e);
    }
  },
  methods: {
    checkChanged(){
        const values = Object.values(this.depot);
          if(values.filter((item) => item).length){
          this.isChanged = true
        }else{
           this.isChanged = false
        }
    },
        showConfirmDialog(){
      if(this.isChanged){
      this.visibleConfirm = true
      }else{
        this.onClose();
      }
    },
    onClose(){
      this.isChanged = false
       this.depot = [];
      this.$v.$reset();
      this.visibility = false
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append('name', this.depot.name);
          if (this.depot.description) {
            formData.append('description', this.depot.description);
          }
          formData.append('uuid_owner', this.getUser.uuid);
          formData.append('uuid_address', this.depot.address_uuid);
          await depotService.addDepot(formData);
          this.loading = false;
          this.$v.$reset();
          this.setSuccessNotification(this.$t('storages.storage_was_created'));
          EventBus.$emit('depot-created');
          this.visibility = false;
          this.clearDepot();
        } catch (e) {
          this.loading = false;
          this.setErrorNotification(e);
        }
      }
    },
    clearDepot() {
      Object.keys(this.depot).forEach((key) => {
        this.depot[key] = '';
      });
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.depot.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.depot.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    uuidErrors() {
      const errors = [];
      if (!this.$v.depot.address_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.depot.address_uuid.required
      && errors.push(this.$t('form.errors.AddressesRequired'));
      return errors;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <DepotsComponent />
</template>

<script>
import store from '@/store';
import DepotsComponent from '../../components/depots/DepotsComponent.vue';

export default {
  name: 'Depots',
  components: {
    DepotsComponent,
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['auth/getPermissions'].can_view_depot_page) {
      next();
      return;
    }
    next(from);
  },
};
</script>

<style scoped>

</style>
